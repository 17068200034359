import React from 'react'
import AccountAccessEnded, {
  AccessEndedReason,
} from '../../../components/AccountAccessEnded'
import Layout from '../../../components/Layout'

export default function TrialEnded() {
  return (
    <Layout noIndex hideNavigation>
      <AccountAccessEnded variant={AccessEndedReason.TrialEnded} />
    </Layout>
  )
}
